import { createContext } from "react";
import defaultConfig from "../configs/default.json";
import { flatten as flat } from "flat";

type Flatten<T extends object> = object extends T
  ? object
  : {
      [K in keyof T]-?: (
        x: NonNullable<T[K]> extends infer V
          ? V extends object
            ? V extends readonly any[]
              ? Pick<T, K>
              : Flatten<V> extends infer FV
              ? {
                  [P in keyof FV as `${Extract<K, string | number>}.${Extract<
                    P,
                    string | number
                  >}`]: FV[P];
                }
              : never
            : Pick<T, K>
          : never
      ) => void;
    } extends Record<keyof T, (y: infer O) => void>
  ? O extends infer U
    ? { [K in keyof O]: O[K] }
    : never
  : never;

export const flatten = <T extends object>(obj: T): Flatten<T> => flat(obj);

export const flatDefaultConfig = flatten(defaultConfig);

type SettingsType = {
  clientKey: string;
  debug: boolean;
  getSetting: (key: keyof typeof flatDefaultConfig) => any;
};

export const Settings = createContext<SettingsType>({
  clientKey: undefined,
  debug: false,
  getSetting: () => undefined,
});

export const SHARE_BASE_URL =
  "https://r7rhn2td61.execute-api.eu-west-2.amazonaws.com/Prod/";
export const RNS_BASE_URL =
  "https://vvwaqxpwpc.execute-api.eu-west-2.amazonaws.com/Prod/";
export const SIGNUP_BASE_URL =
  "https://yf96lmbqob.execute-api.eu-west-2.amazonaws.com/Prod/";
export const CONFIGURATION_BUCKET_URL =
  "https://widgets.irdataservices.com";

export default Settings;
