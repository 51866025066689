import {lazy} from "react";

const widgets = {
	"share-price-and-table": lazy(() => import("./share-price-and-table")),
	"share-price": lazy(() => import("./share-price")),
	"share-price-header": lazy(() => import("./share-price-header")),
	"share-calculator": lazy(() => import("./calculator")),
	"rns-listing": lazy(() => import("./RNSListing")),
	"rns-mini": lazy(() => import("./RNSMini")),
	"rns-signup": lazy(() => import("./SignUp")),
}

export default widgets;