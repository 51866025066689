import React, {
  Component,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { Suspense, lazy } from "react";
import Settings, {
  CONFIGURATION_BUCKET_URL,
  flatDefaultConfig,
  flatten,
} from "./settings";
import clients from "./clients"
import Rollbar from "rollbar/dist/rollbar.noconflict.umd";

const rollbarConfig = {
  accessToken: "f08687762d7e4ff4a7e6d948cd1bee3e",
  captureUncaught: false,
  captureUnhandledRejections: false,
  payload: {
    environment: process.env.NODE_ENV,
  },
};

const rollbar = new Rollbar(rollbarConfig);

type ErrorProps = {
  debug: boolean;
};

class ErrorBoundary extends Component<ErrorProps> {
  state = { error: null };

  static getDerivedStateFromError(error) {
    return { error: error.message };
  }

  componentDidCatch(error) {
    console.error(error);
    rollbar.error("Widget Error", error);
    this.setState({ error: error.message });
  }

  render() {
    if (this.state.error) {
      return (
        <div className="debug-error">
          <p>Looks like something has gone wrong</p>
          {this.props.debug && <p>{this.state.error}</p>}
        </div>
      );
    }
    // @ts-ignore
    return this.props.children;
  }
}

function DebugWarning({ message }) {
  const { debug } = useContext(Settings);
  if (!debug) return null;
  return (
    <div className="debug-warning">
      <p>Your widget is not configured correctly:</p>
      <p>{message}</p>
    </div>
  );
}

type WidgetSwitcherProps = {
  clientKey: string;
  widgetType: string;
  forexDataType?: string;
};

const widgets = {
  "rns-list": lazy(() => import("./widgets/legacy/rns/List")),
  "rns-listing": lazy(() => import("./widgets/RNSListing")),
  "rns-mini": lazy(() => import("./widgets/RNSMini")),
  "mini-rns": lazy(() => import("./widgets/legacy/rns/MiniList")),
  "rns-signup": lazy(() => import("./widgets/legacy/rns/SignUp")),
  "share-price": lazy(() => import("./widgets/legacy/share/Price")),

  "share-price-detailed": lazy(() => import("./widgets/SharePriceDetailed")),
  "share-price-chart": lazy(() => import("./widgets/SharePriceChart")),
  "share-price-header": lazy(() => import("./widgets/SharePriceHeader")),
  "share-price-standard": lazy(() => import("./widgets/SharePriceStandard")),
  "share-price-trades": lazy(() => import("./widgets/SharePriceTrades")),
  "share-price-info": lazy(() => import("./widgets/SharePriceInfo")),

  "share-price-nmt": lazy(() => import("./clients/neometals/SharePrice")), // Remove when -nmt removed from client site
  "share-price-mini-nmt": lazy(() => import("./clients/neometals/MiniSharePrice")),
  "share-price-mini": lazy(() => import("./widgets/legacy/share/MiniPrice")),
  "share-calculator": lazy(() => import("./widgets/legacy/share/Calculator")),
  "share-table": lazy(() => import("./widgets/legacy/share/Table")),
  "rns-listing-mb": lazy(() => import("./clients/mcbride/RNSListing")),
  "forex-chart": lazy(() => import("./widgets/ForexChart")),
}

const WidgetSwitcher: React.FC<WidgetSwitcherProps> = ({ clientKey, widgetType, forexDataType }) => {
  const Widget = {
    ...widgets,
    ...clients[clientKey]
  }[widgetType]
  if (!Widget) {
    return <DebugWarning message={`Invalid widget type: ${widgetType}`} />
  }
  return <Widget forexDataType={forexDataType}/>
};

export const App = ({
  widgetType,
  clientKey,
  debug = "false",
  overriddenConfig = null,
  forexDataType = "fx",
}) => {
  const [config, setConfig] = useState(null);

  useEffect(() => {
    Rollbar.configure({
      payload: {
        widgetType,
        clientKey,
      },
    });
  });

  useEffect(() => {
    async function fetchConfig(overriddenConfig) {
      let data = {};
      try {
        const res = await fetch(
            process.env.NODE_ENV === "development"
                ? `/configs/${clientKey}.json`
                : `${CONFIGURATION_BUCKET_URL}/${process.env.ENVIRONMENT || "development"}/configs/${clientKey}.json`
        );
        data = await res.json();
        setConfig(data);
      } catch (e) {
        console.error("Unable to fetch remote config:", e);
      }

      setConfig({
        ...data,
        ...JSON.parse(overriddenConfig),
      });
    }

    fetchConfig(overriddenConfig);
  }, [overriddenConfig]);

  const flatConfig = useMemo(() => (config ? flatten(config) : null), [config]);

  const getSetting = useCallback(
    (key: keyof typeof flatDefaultConfig) => {
      if (!flatConfig) {
        return null;
      }
      if (flatConfig[key] === null || flatConfig[key] === undefined) {
         return flatDefaultConfig[key];
       }
       return flatConfig[key];
    },
    [flatConfig]
  );

  if (!config) {
    return <DebugWarning message="Loading config" />;
  }

  return (
    <Suspense fallback={<span />}>
      <Settings.Provider
        value={{
          clientKey,
          debug: debug === "true",
          getSetting,
        }}
      >
        <div className={`ir-widget ${widgetType}`}>
          {/*@ts-ignore*/}
          <ErrorBoundary debug={debug === "true"}>
            <WidgetSwitcher clientKey={clientKey} widgetType={widgetType} forexDataType={forexDataType}/>
          </ErrorBoundary>
        </div>
      </Settings.Provider>
    </Suspense>
  );
};
