import { App } from "./index";
import { CONFIGURATION_BUCKET_URL } from "./settings";
import { render } from "react-dom";

const head = document.getElementsByTagName("head")[0];
const link = document.createElement("link");
link.id = "ir-widget-styles";
link.rel = "stylesheet";
link.type = "text/css";
link.href = require("url:./styles/style.scss");
head.appendChild(link);

const elements = document.querySelectorAll("[data-widget]");

// @ts-ignore
for (const element of elements) {
  const { widgetType, clientKey, styleKey, debug, config, forexDataType } = element.dataset;

  if (styleKey !== "none" && clientKey) {
    let theme = `${CONFIGURATION_BUCKET_URL}/${
      process.env.ENVIRONMENT || "development"
    }/configs/${styleKey || clientKey}.css`;

    const link = document.createElement("link");
    link.rel = "stylesheet";
    link.type = "text/css";
    link.href = theme;
    head.appendChild(link);
  }

  render(
    <App
      widgetType={widgetType}
      clientKey={clientKey}
      debug={debug}
      overriddenConfig={config}
      forexDataType={forexDataType}
    />,
    element
  );
}
